import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideo from "components/Motion_Detection/Router_as_a_FTP_Server/Telekom_Speedport_as_FTP_Server/YoutubeVideoCardSpeedNAS";
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/Router_as_a_FTP_Server/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Telekom Speeport as FTP Server",
  "path": "/Motion_Detection/Router_as_a_FTP_Server/Telekom_Speedport_as_FTP_Server/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Use your Router / NAS as a FTP Server",
  "image": "./MD_SearchThumb_RouterAsFTPServer_Speedport.png",
  "social": "/images/Search/MD_SearchThumb_RouterAsFTPServer_Speedport.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Router-FTP-Server_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Telekom Speeport as FTP Server' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the FTP service of your router or network drive to back up your alarm video and images.' image='/images/Search/MD_SearchThumb_RouterAsFTPServer_Speedport.png' twitter='/images/Search/MD_SearchThumb_RouterAsFTPServer_Speedport.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Motion_Detection/Router_as_a_FTP_Server/Telekom_Speedport_as_FTP_Server/' locationFR='/fr/Motion_Detection/Router_as_a_FTP_Server/Telekom_Speedport_as_FTP_Server/' crumbLabel="Speedport" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "local-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#local-ftp-server",
        "aria-label": "local ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Local FTP Server`}</h2>
    <h2 {...{
      "id": "telekom-speeport-as-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#telekom-speeport-as-ftp-server",
        "aria-label": "telekom speeport as ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Telekom Speeport as FTP Server`}</h2>
    <YoutubeVideo mdxType="YoutubeVideo" />
    <h3 {...{
      "id": "configure-your-speedport-as-a-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configure-your-speedport-as-a-ftp-server",
        "aria-label": "configure your speedport as a ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure your Speedport as a FTP Server`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/57549ee38f6e74059e9c1b0cb103f7df/8c557/Speedport-FTP_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABTUlEQVQY032PTUvDQBiE84fbpolNmzbZJv0A6cWzBy/irV7EP1BQUNCiQWIRxKJgaWma7G6ym26y2dVokZ58bgPvzDujaJpmGIau66qqGo1G1wa1WrVpOXqjqWlqRa8eW+B2fH59enZ40KjU623T1HUdAOB5ntJpt51uFwBgWZZt2z3XNVstdzC0QRfYtuXYJ6PR1cXlZDw+6vXMTmc4GDiO2+/3fd9XMNuyopD/UwgphJQyz7Ji71hZTW7Q61xIyTmHEG6CIFivEYy2aQklFEIIEcJxTCkKwyAMI0op51wIoYi3DxFGZY4QhDFECEoSyljGecayHXkJYynGCCFM03T3ea9bQfzn1cPj5/10eTcli8UaLxGCGGMYwRjHOc8pTZOE/DVXyjE/e4QoyOwl8J5Cf5bP3yXCMUkwQjGOOedSShKiaL3Zsu2v/DZ/ASUpXoRaT9HLAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/57549ee38f6e74059e9c1b0cb103f7df/e4706/Speedport-FTP_01.avif 230w", "/en/static/57549ee38f6e74059e9c1b0cb103f7df/d1af7/Speedport-FTP_01.avif 460w", "/en/static/57549ee38f6e74059e9c1b0cb103f7df/70e80/Speedport-FTP_01.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/57549ee38f6e74059e9c1b0cb103f7df/a0b58/Speedport-FTP_01.webp 230w", "/en/static/57549ee38f6e74059e9c1b0cb103f7df/bc10c/Speedport-FTP_01.webp 460w", "/en/static/57549ee38f6e74059e9c1b0cb103f7df/426ac/Speedport-FTP_01.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/57549ee38f6e74059e9c1b0cb103f7df/81c8e/Speedport-FTP_01.png 230w", "/en/static/57549ee38f6e74059e9c1b0cb103f7df/08a84/Speedport-FTP_01.png 460w", "/en/static/57549ee38f6e74059e9c1b0cb103f7df/8c557/Speedport-FTP_01.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/57549ee38f6e74059e9c1b0cb103f7df/8c557/Speedport-FTP_01.png",
              "alt": "Telekom Speeport as FTP Server",
              "title": "Telekom Speeport as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Connect your USB drive with Telekom router. Then open the web user interface and go to Home Network / Network Storage to verify that your drive was mounted.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6fd33ee7f254217165e3af9e377014dc/8c557/Speedport-FTP_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "97.82608695652175%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACKElEQVQ4y3WT227bMAyG8/5PUwzDbvYAG7qhGDCsWbv1lPgk2xIl6nz0ELtJm9b78F8J+kVSJDcSUXDOAVJK04z3nlLKGBNC5HQgv2I6knPekJ40pGu6zhiznKaUYoyLZzonxoCIWmsppbV2Aw/t+Leij6119hQZGAMAxtg4jojovXczxhgppVJKotRab6aQD/IvQZxz9cxut6eUSqX0EWOMPWKM2Zw8pZRTZEJI3/dKqeW2+Q+b6R3OuWpfVVUtpTTG6FfMEV9Cr5hTSj4lF2PIOU3TSbFkoRRHyVECorZ2xRytUbsqA584L3CmMAx+Ud9HgHPzXLbfV/2XS1nV0ziWWROlareHu/tIet91BxFitzdr5qoZf15r4JMxZdZkLfaEPD5ZBpZBRIxau/uHFXPSJu2r3LSpblLd5LrJTROednx7C9sb9uu32+3dw6Nv2pWalyF8M1yxFJeLixEQQSAaY0NYMQfvSd31LVEorTFLOsFYM1AJnI6MUwjeh+DXfjvGQ0+VRiGNNofNKMVe/eCX38L11scoEUlHlNLrfV7WIpXDUnnvQ4x4+6f6fmWfdsvrnHHv19KO3ot+EAMVZODDaK0rpaRSYsnL/AkhgIF+G3kuz93d9Rcfuk+fm4uP6uvl8cWAIDjlI6HWuud9XknbezWM0BJWt1bg8y+GwEHQnpKGWDPnktJ6q15SOT+XUkpEDhwA7Ops55zLO1JKfCbGqJQE4CGEf1YFhEZO5aQoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6fd33ee7f254217165e3af9e377014dc/e4706/Speedport-FTP_02.avif 230w", "/en/static/6fd33ee7f254217165e3af9e377014dc/d1af7/Speedport-FTP_02.avif 460w", "/en/static/6fd33ee7f254217165e3af9e377014dc/70e80/Speedport-FTP_02.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6fd33ee7f254217165e3af9e377014dc/a0b58/Speedport-FTP_02.webp 230w", "/en/static/6fd33ee7f254217165e3af9e377014dc/bc10c/Speedport-FTP_02.webp 460w", "/en/static/6fd33ee7f254217165e3af9e377014dc/426ac/Speedport-FTP_02.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6fd33ee7f254217165e3af9e377014dc/81c8e/Speedport-FTP_02.png 230w", "/en/static/6fd33ee7f254217165e3af9e377014dc/08a84/Speedport-FTP_02.png 460w", "/en/static/6fd33ee7f254217165e3af9e377014dc/8c557/Speedport-FTP_02.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6fd33ee7f254217165e3af9e377014dc/8c557/Speedport-FTP_02.png",
              "alt": "Telekom Speeport as FTP Server",
              "title": "Telekom Speeport as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now expand the Manage User section to add a FTP user for your camera - add a username and password and check the Allow FTP Access box.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9d66e2d2912d8b0fdd4912fd2d802797/e17e5/Speedport-FTP_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAByklEQVQoz9XSS2/TMADA8Xxj2IHH4BOwGxzQArRKxJA4VGJaT2lDS6t2O0TdAa1BNC+neaxL07gNcew8jZJ22obQ2JXfxfLjLx9spvH+Q6fTabfb7ZOKIHR6vd7Xe4mi2O/3W60Wc3Z2KsuyJEnT6cVofOo4LsY4uguhCCEUX0MIJUmiaRrT7XaTJAmCYBNWIIQwqNUjhHC9XsPa8prneRBCVVUZQRCKonAcxwRA13XbtoAJLMuazy3btnVdUxTVAMAEZhzHJCGEEIxxnufVzaIoUkrDMFxcuqqiz2a6oRpAA5piANWYAysMw1+VqCgKWivLklJqGAYzHo8ppYRg93LxuWe9PdaBiygt0jTL0izPd8Ft29g0zV0cRZHnXf2YaXN3WZT0fn/GRW27+k9/iemD3cSj0ei/iMuySuvDJgDMcDhM0xRCuHkIHG8wXiOEskwzTWYwGNTv5Pm+v/16y1u2093WYrGUZW964clyIMuzyYSRJIlSGqyCle9jjLMswxgTcvMNE0KC1Sopaa5q+fl58f1n+m1CFe3qyzHz6uDg09ERe8i+Y9lGo8HfxXFcs9lkWbbJ89zrN/yz5/zTF9yjJx/3Xx4+3vsNlDRl94PbdiYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9d66e2d2912d8b0fdd4912fd2d802797/e4706/Speedport-FTP_03.avif 230w", "/en/static/9d66e2d2912d8b0fdd4912fd2d802797/af71e/Speedport-FTP_03.avif 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9d66e2d2912d8b0fdd4912fd2d802797/a0b58/Speedport-FTP_03.webp 230w", "/en/static/9d66e2d2912d8b0fdd4912fd2d802797/7f61c/Speedport-FTP_03.webp 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9d66e2d2912d8b0fdd4912fd2d802797/81c8e/Speedport-FTP_03.png 230w", "/en/static/9d66e2d2912d8b0fdd4912fd2d802797/e17e5/Speedport-FTP_03.png 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9d66e2d2912d8b0fdd4912fd2d802797/e17e5/Speedport-FTP_03.png",
              "alt": "Telekom Speeport as FTP Server",
              "title": "Telekom Speeport as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on Browse in the previous screenshot to create an upload directory for your camera on your external drive.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/08af064d5d515ff35f3893294f174cb3/e17e5/Speedport-FTP_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "129.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAIAAAA44esqAAAACXBIWXMAAAsTAAALEwEAmpwYAAACbUlEQVQ4y5WUzVKjQBSF8+4+gCsr6nZ8AxdqzcYqRxOgIZA0P6EDNCENDXR3guEnli5mCE5NaUbFb0HRQNc9nHtuD7wFUgAYjUbX19c3Nz8pTYUQvAeMsQGlFGMc+H4YhoSQJEnifiRJMmCM2bYNIdR1IwyXrB95nmdZNuCce4sFxth13SAIqqpqvmK325VlSQhpK7vzuaEbCKEkSUhvOOftZoTcKIooTYtNsemHEIJSOuCceZ4vhKjretuboij+GTabToECfD8gJP6e7DAMpzM4m5lFUdR1XX1FXdePj49xHA84YxjjhedhHFKacs5FDzjnaZq2lR3bnhqGoRudckppl4HPaRPGObMsC0KIMX5+ftntmm/0mXOOEJo784XnBQEmhGRZlvYgz/O925ala5PxeKyqGiFxl76+8XRd1zRNOJu5CO2eWpqmqT+maZrtdtu6zRhboIUJTeS6tmUbhq7rumM7cRx/3WfOOYRQkRVN1SzLIYREUZRmWfEp6/W6i+fesLkzn7eelWVZVVUXwM83v8bTcWxZlhUZxHG8Wq2iKFr9hRDy0WHwKjvwPdtxNpvN0wFlWR5ORVVVCCHf99vKkjS+vb2V99zf30uS1N1LkrRcLjnnWZblb1mtVu0/Z1n24+JiOByenZ2eng6Pjo6Oj4/Pz9vFycmJabbTwhh7d/oJIdbrdSt7omkqAIqqyopiTPSJpkmyrAINqGC5jIqi6D59hxBiIITo2nNIHMedyDRNswNaw/I8V1UVAKDuASpoL/snV1dXl5eXv+7uAADKWyRJCsOwNcyyLPN/jMfjh4eRMTUOX0EI/0zVb81vcjrxoNLkAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/08af064d5d515ff35f3893294f174cb3/e4706/Speedport-FTP_04.avif 230w", "/en/static/08af064d5d515ff35f3893294f174cb3/af71e/Speedport-FTP_04.avif 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/08af064d5d515ff35f3893294f174cb3/a0b58/Speedport-FTP_04.webp 230w", "/en/static/08af064d5d515ff35f3893294f174cb3/7f61c/Speedport-FTP_04.webp 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/08af064d5d515ff35f3893294f174cb3/81c8e/Speedport-FTP_04.png 230w", "/en/static/08af064d5d515ff35f3893294f174cb3/e17e5/Speedport-FTP_04.png 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/08af064d5d515ff35f3893294f174cb3/e17e5/Speedport-FTP_04.png",
              "alt": "Telekom Speeport as FTP Server",
              "title": "Telekom Speeport as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open your camera´s webUI and add your routers FTP data. The default IP address is 192.168.2.1, the port is 21, the folder is ./Folder Name and then add your FTP user that you created earlier.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5a49f51be4f5affb7476485abe3ca9da/8c557/Speedport-FTP_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "23.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAiElEQVQY042OsRKDMAxD+f8PLNelA50CxCTgNLZjx+1d2doBDZr0JA3MwkzMRF8n4gsSkVLKEGHZYl4XiGsCSBFg33e/ICIaELclvI7cymFU1cxU7TcqLKpaa0XE1toJG9Xn/QFJEd30z0Lv3d3nOeSUp2m6jWMI4YS9d1dT8ybe7crfs+4DvwGubSRfDtC/XAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a49f51be4f5affb7476485abe3ca9da/e4706/Speedport-FTP_07.avif 230w", "/en/static/5a49f51be4f5affb7476485abe3ca9da/d1af7/Speedport-FTP_07.avif 460w", "/en/static/5a49f51be4f5affb7476485abe3ca9da/70e80/Speedport-FTP_07.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5a49f51be4f5affb7476485abe3ca9da/a0b58/Speedport-FTP_07.webp 230w", "/en/static/5a49f51be4f5affb7476485abe3ca9da/bc10c/Speedport-FTP_07.webp 460w", "/en/static/5a49f51be4f5affb7476485abe3ca9da/426ac/Speedport-FTP_07.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a49f51be4f5affb7476485abe3ca9da/81c8e/Speedport-FTP_07.png 230w", "/en/static/5a49f51be4f5affb7476485abe3ca9da/08a84/Speedport-FTP_07.png 460w", "/en/static/5a49f51be4f5affb7476485abe3ca9da/8c557/Speedport-FTP_07.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5a49f51be4f5affb7476485abe3ca9da/8c557/Speedport-FTP_07.png",
              "alt": "Telekom Speeport as FTP Server",
              "title": "Telekom Speeport as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on Submit, then Test in the previous screenshot. You can then type in the routers address `}<strong parentName="p">{`ftp://192.168.2.1/"Folder Name"`}</strong>{` into your browser´s address bar - the test snapshot should be uploaded.`}</p>
    <h3 {...{
      "id": "configure-your-camera-for-the-ftp-upload",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#configure-your-camera-for-the-ftp-upload",
        "aria-label": "configure your camera for the ftp upload permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configure your Camera for the FTP Upload`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/71d8b46e4ec51776dbd577f2436842d9/e17e5/Speedport-FTP_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "150.43478260869568%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAIAAACjcKk8AAAACXBIWXMAAA7EAAAOxAGVKw4bAAADd0lEQVQ4y4WU2XLiOBRA9f8/MA9dNenp9Ex39ZLOBiQBs9lAAvEesOywGGxsS9bG+jxjk5meDgk5dcpWWXVLV/LVBZZlFovFlqJUpGqxUHIgZIxhjNM0JQfBGIMoJ4l3RHEcY4xwzm4qfondd8AYg9CBrue6XvaGLszMYIwLIfgeQog0B1BKfX8y8TPG4/HkaZgRxzFCmO3BOd/tK1uZkGx7aZrSp808pZ0kCcYvBOchhFKarYwQSrJFUBRFGONnSbLXAWmautDt9weP3qOhG6PRiB0M2EFzAKXk0fW87MS8yXDkDAZJFC+E4IxxRhl9Qc4YRijFGFAhZkkyjeNZkgQITaM4YSwiaURSLBZ8s2Hr9b5ktSRCAPo4fPjy3fz63f72o39yZnz+As8uBqfn9reTYeGK33ZZ5+6Z/K6XlKtINwCt1YcXBa/eHNYaw3rTrVR9uT1uyL6seFIt7qncfmCW/VPTEhBOS1fx+SWg01lYb87qzbAhR3IryMeR0gkactBo4s6d0HR2r/6iqtHOLYUuoJ5nn1+Yl0XrsghvKurJqX56Pri+ccqVh2LpUapRw2K/yu1+JCvItACVFfe3d4P3x/Dow+j4U//dkfP7H6OPn0cf/xr9+QkefYhPzkTpmhevniyUFjeV8P0xLpQATVPn/t5od1xN73d7htKKxxMxj1gY8nAu5hENAjKbPZMFAct+FWMOdHXLhkPPHY4I52wh+HL5n2K1euZivXaHw7HvZ+UJHQc60PNcB8JdxeO3CMMQIQQIIaZp9PsDCB3btnfXiL7FfD5PkiSr7VkQbLfbTc5qteRvIYRwXdfP0ibE1DS1p5q6oauqZVrZDUuSw+IEMUpBKrjrT2JKseC5Qmy3fLM+oNhuvOnUD0NA79Xx2flcqsW587I0L0tUaR2QtTtBoRTfdQEpV7SqpCpto922bju9el1T2njiH3IWzC0blysgvb6ZmtYqTdcIrxFaY7zGWETRARcYw1Z7fPIDMFUjtQZR2qSpULlF5BaVs9yI/LpKm0g1qhuALZeIc8yzo3p6Cp4uxIs94KebNV0uAUnTwYM96A9cB/btBxdCyzA86PG8RzLyahvCCIH/N7SMf9sqpXTX8V8ruKx7Yox7vZ6+h6apkiRVq9Vut2sYxt6sFgRBFqyqqrGHruvVnPtezzTN/dl/gv8GxIklhRoCjYkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/71d8b46e4ec51776dbd577f2436842d9/e4706/Speedport-FTP_05.avif 230w", "/en/static/71d8b46e4ec51776dbd577f2436842d9/af71e/Speedport-FTP_05.avif 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/71d8b46e4ec51776dbd577f2436842d9/a0b58/Speedport-FTP_05.webp 230w", "/en/static/71d8b46e4ec51776dbd577f2436842d9/7f61c/Speedport-FTP_05.webp 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/71d8b46e4ec51776dbd577f2436842d9/81c8e/Speedport-FTP_05.png 230w", "/en/static/71d8b46e4ec51776dbd577f2436842d9/e17e5/Speedport-FTP_05.png 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/71d8b46e4ec51776dbd577f2436842d9/e17e5/Speedport-FTP_05.png",
              "alt": "Telekom Speeport as FTP Server",
              "title": "Telekom Speeport as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now you can let the camera upload snapshots or videos in case of an alarm.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/45cd7bc8dc577e5bbd13ba047cb6dcdb/e17e5/Speedport-FTP_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "112.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAXCAIAAACEf/j0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB60lEQVQ4y5WT7XKiMBiFc/9343T9tWtn3bVltDt8CSS0VDEJhHwQYvQCdgLVPyra5wdkJrwv5+Q9Ab+fn3/Nfr68vj79ePrz90Xr7kG01oAxRimtqopSgjGpHqauayCE4JzXjEkplVLtwwghAMYYZTCDME1SxpgxptNfqsZhjAEphVPAatqLORtQSt2tB5xzjMkgXvQvwd36rgWlFJBCNg0fdLbfQUoJ8G6XrJPA94vi01qrT7N6yLMQoh+VM8t66p62be97FpzTqmINq+v+4BgbxjhMbgQnW0qptbbW7nvOXR+xDSpafRbFe0/x8aFavd8bY8x4vdZ6s9kAgjHMYI5yBFGapOW2LMtyuy05FyPH1nXdV3GWwTTJiqJAEKEMpmkaRzEhdPj5reKyLAGlFEKYJel6nWROwnue52mSIpSHvu/7QV3XXdddduGcu3hSQiihGGNCCK3oQMMa1cdoBDfnIZtNzymk7jEeFVdMCQ6CKA4j3w/iMIrcMvz39kYoPXe8ipO93W4QyrXWUkil2nMAjDH721hr3X3e7XYY4+PxeDjYgcPBHvrMmBM3i93t77qrYRxGottWXdt1CSuKwvf96IIwDBeLxXw+Xy6XURxffhAEAUAIeZ63vMZ0Op1MJrPZbLVaXe56nvcfn8PZopCV62IAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/45cd7bc8dc577e5bbd13ba047cb6dcdb/e4706/Speedport-FTP_06.avif 230w", "/en/static/45cd7bc8dc577e5bbd13ba047cb6dcdb/af71e/Speedport-FTP_06.avif 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/45cd7bc8dc577e5bbd13ba047cb6dcdb/a0b58/Speedport-FTP_06.webp 230w", "/en/static/45cd7bc8dc577e5bbd13ba047cb6dcdb/7f61c/Speedport-FTP_06.webp 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/45cd7bc8dc577e5bbd13ba047cb6dcdb/81c8e/Speedport-FTP_06.png 230w", "/en/static/45cd7bc8dc577e5bbd13ba047cb6dcdb/e17e5/Speedport-FTP_06.png 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/45cd7bc8dc577e5bbd13ba047cb6dcdb/e17e5/Speedport-FTP_06.png",
              "alt": "Telekom Speeport as FTP Server",
              "title": "Telekom Speeport as FTP Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Or upload a snapshot series (maybe to create a time lapse video from it afterwards).`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      